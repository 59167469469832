<template>
	<MhRouterView class="NotFoundView view" :isContentReady="isContentReady">

		<div class="view__inner">

			<!--
			<h1>404 for url "{{currentUrl}}"</h1>
			-->
			<ZaNotFound></ZaNotFound>

			<ZaAppFooter
				:colum1="_.get( app.acfOptions, 'appFooter__colum--1' )"
				:colum2="_.get( app.acfOptions, 'appFooter__colum--2' )"
				:colum3="_.get( app.acfOptions, 'appFooter__colum--3' )"
			></ZaAppFooter>

		</div>

	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'
	import ZaNotFound from '@/components/ZaNotFound.vue'
	import ZaAppFooter from '@/components/ZaAppFooter.vue'

	export default {
		name: 'NotFoundView',
		components: {
			MhRouterView,
			ZaNotFound,
			ZaAppFooter,
		},
		props: {},
		data() {
			return {
				isContentReady : false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.NotFoundView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
